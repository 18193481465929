// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconFlashOn = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9996 2.17514C10.9046 6.16889 7.12834 12.0301 5.68084 17.5651C5.41834 20.7226 10.6946 17.5276 12.2658 16.7814C12.3408 16.7476 12.4196 16.8189 12.3933 16.8976L10.9233 21.4389C10.8033 21.8139 11.2683 22.0989 11.5458 21.8214C14.6133 19.1214 24.0896 5.61764 13.3083 11.7826C13.2371 11.8239 13.1471 11.7601 13.1696 11.6776L15.6221 2.54264C15.7196 2.17514 15.2696 1.90889 14.9996 2.17514Z" />
    </svg>
  );
};

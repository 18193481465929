// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronRightSmall = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.2831 17.1159C9.06935 17.3606 8.97091 17.684 9.00748 18.0046C9.04404 18.3253 9.21842 18.6178 9.4856 18.8062C9.67404 18.9412 9.89623 19.0059 10.1212 19.0059C10.4587 19.0059 10.8019 18.8596 11.0437 18.584L15.4819 13.4962C16.2244 12.6468 16.2244 11.3643 15.4819 10.515L11.01 5.38777C10.7934 5.13745 10.4784 4.99402 10.1465 4.99402C9.8906 4.99402 9.63748 5.08121 9.43498 5.24152C9.20154 5.42714 9.05529 5.70277 9.02716 6.01496C8.99623 6.35246 9.10592 6.68995 9.32529 6.94308L13.6481 11.8987C13.7015 11.9606 13.7015 12.0534 13.6481 12.1125L9.2831 17.1159Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMinus = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3017 12.9375H5.698C5.1805 12.9375 4.7605 12.5175 4.7605 12C4.7605 11.4825 5.1805 11.0625 5.698 11.0625H18.3017C18.8192 11.0625 19.2392 11.4825 19.2392 12C19.2392 12.5175 18.8192 12.9375 18.3017 12.9375Z" />
    </svg>
  );
};

import { type FC } from "react";
import { TRANSLATOR_CONTEXT, type Translator } from "./i18n";
import { useOnFilesChange, useStore } from "./state";

import Dropzone, { Props as DropzoneProps } from "./Dropzone";
import Preview from "./Preview";
import { useSnapshot } from "valtio";
import useAfterUnmount from "../../../web/src/hooks/useAfterUnmount";

export type Props = Pick<
  DropzoneProps,
  "onCancel" | "onInvalidFileDrop" | "prompt"
> & {
  allowedFileConfig: { extensions: string[]; maxFiles: number };
  onChange?(files: File[]): void;
  translate: Translator;
  clearOnUnmount?: boolean;
};

const FilePicker: FC<Props> = ({
  allowedFileConfig,
  prompt,
  onCancel,
  onChange,
  onInvalidFileDrop,
  translate,
  clearOnUnmount = false,
}) => {
  const store = useStore();
  const { files } = useSnapshot(store);

  const maxAllowedFiles = allowedFileConfig.maxFiles;

  useOnFilesChange((files) => onChange?.(files));
  useAfterUnmount(() => {
    if (!clearOnUnmount) return;
    store.files = [];
  });

  return (
    <TRANSLATOR_CONTEXT.Provider value={translate}>
      <Dropzone
        allowedFileExtensions={allowedFileConfig.extensions}
        maxAllowedFiles={maxAllowedFiles}
        onCancel={onCancel}
        onInvalidFileDrop={onInvalidFileDrop}
        prompt={prompt}
      >
        <Preview files={files} />
      </Dropzone>
    </TRANSLATOR_CONTEXT.Provider>
  );
};

export default FilePicker;

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconLink = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.471 7.16281C20.426 5.81656 19.8035 4.59031 18.7385 3.76531C16.841 2.29531 14.1073 2.63656 12.551 4.46656C11.6323 5.54656 10.4735 6.9153 9.79476 7.71405C9.05226 8.5878 8.63601 9.70905 8.68851 10.8528C8.74476 12.109 9.30726 13.2565 10.2598 14.0666C10.631 14.3815 11.0435 14.6253 11.4748 14.7978C11.6248 14.8578 11.7973 14.8128 11.9023 14.689L12.131 14.4191C12.3635 14.1453 12.4985 13.8078 12.521 13.4515C12.5322 13.2903 12.4235 13.1441 12.2698 13.0916C11.9885 12.9903 11.7185 12.8403 11.4785 12.634C10.8973 12.139 10.5635 11.4303 10.5635 10.6541C10.5635 10.0241 10.8073 9.42031 11.216 8.94031L14.036 5.61781C14.9623 4.52656 16.601 4.39529 17.6885 5.31779C18.2698 5.81279 18.6035 6.5253 18.6035 7.2978C18.6035 7.9278 18.3598 8.53156 17.951 9.01156L16.0535 11.2465C15.971 11.344 15.941 11.479 15.9823 11.599C16.1248 12.019 16.2185 12.454 16.256 12.9078C16.271 13.084 16.2748 13.2641 16.271 13.4403C16.2673 13.5941 16.4585 13.6691 16.556 13.5528L16.5598 13.549L19.3648 10.2415C20.096 9.38655 20.5085 8.29156 20.471 7.16281Z" />
      <path d="M3.5285 16.8228C3.566 18.169 4.18851 19.3953 5.24976 20.224C7.14351 21.7015 9.87726 21.3715 11.4373 19.5453C12.4385 18.3753 13.721 16.8716 14.366 16.1178C14.7073 15.7166 14.9735 15.2553 15.1235 14.7528C15.6672 12.9116 15.1047 11.0966 13.766 9.95281C13.3947 9.63406 12.986 9.39031 12.5548 9.21781C12.4048 9.15781 12.2323 9.19904 12.1272 9.32279L11.8985 9.58905C11.6623 9.8628 11.5273 10.2003 11.5048 10.5528C11.4935 10.7141 11.6023 10.8603 11.756 10.9165C12.0373 11.0178 12.3072 11.1716 12.5472 11.3778C13.1285 11.8728 13.4548 12.5853 13.4548 13.3616C13.4548 13.9916 13.2073 14.5953 12.7985 15.0753L9.96725 18.3866C9.03725 19.4741 7.3985 19.6016 6.31475 18.6716C5.7335 18.1766 5.40726 17.4641 5.40726 16.6878C5.40726 16.0578 5.65476 15.454 6.06351 14.974L7.96851 12.7465C8.05101 12.649 8.08101 12.514 8.03976 12.394C7.89726 11.974 7.80725 11.5353 7.76975 11.0853C7.75475 10.909 7.75101 10.7291 7.75476 10.5528C7.75851 10.3991 7.57101 10.3241 7.46976 10.4403L7.466 10.4441L4.64599 13.7403C3.91474 14.5991 3.49475 15.694 3.5285 16.8228Z" />
    </svg>
  );
};

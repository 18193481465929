// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconHelpCircleFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM12.03 17.5425C11.2837 17.5425 10.7062 16.9462 10.7062 16.2188C10.7062 15.4913 11.2875 14.865 12.03 14.865C12.7725 14.865 13.3538 15.4762 13.3538 16.2188C13.3538 16.9613 12.7725 17.5425 12.03 17.5425ZM13.1588 12.78C13.1062 12.9075 13.0725 13.0538 13.0463 13.2C12.9675 13.68 12.5513 14.0325 12.0638 14.0325C11.475 14.0325 11.0212 13.53 11.0662 12.945C11.0738 12.8325 11.0887 12.7238 11.1075 12.6187C11.3925 11.1038 12.795 11.0512 12.87 10.0237C12.9075 9.5025 12.4837 9.0525 11.9625 9.0225C11.4975 8.9925 11.1825 9.2025 10.9575 9.47625C10.9537 9.48 10.95 9.4875 10.9462 9.49125C10.62 9.91125 10.0387 10.0388 9.585 9.76125C9.1125 9.4725 8.94 8.8575 9.22125 8.385C9.255 8.32875 9.28875 8.2725 9.33 8.21625C9.91875 7.37625 10.8863 6.81375 12.1613 6.81375C13.635 6.81375 14.9137 7.64625 14.9137 9.49125C14.9137 11.1712 13.6237 11.6287 13.1588 12.7838V12.78Z" />
    </svg>
  );
};

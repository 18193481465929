import { type RefObject, useEffect, useRef } from "react";
import * as logClient from "@classdojo/log-client";
import { generateFrom, heic, anyImage, anyVideo } from "./generators";
import NO_THUMBNAIL from "./no-thumbnail.svg";

const generateThumbnail = generateFrom(heic, anyImage, anyVideo);

export const useMediaThumbnail = (
  file: File,
  img: RefObject<HTMLImageElement>,
) => {
  const reader = useRef(new FileReader());

  useEffect(() => {
    const ref = reader.current;

    const handler = async () => {
      const thumbnail = await generateThumbnail(file, reader.current.result);

      if (reader.current && img.current) {
        const result = thumbnail ? "success" : "failure";
        logClient.sendMetrics([
          {
            metricName: "file_upload.thumbnail_generation",
            type: "increment",
            value: 1,
            tags: { file_type: file.type || "unknown", result },
          },
        ]);

        if (thumbnail) {
          img.current.style.width = "100%";
          img.current.style.height = "100%";
          img.current.src = thumbnail;
        } else {
          img.current.style.width = "40%";
          img.current.style.height = "40%";
          img.current.src = NO_THUMBNAIL;
        }
      }
    };

    ref.addEventListener("loadend", handler);
    ref.readAsDataURL(file);

    return () => {
      ref.removeEventListener("loadend", handler);
    };
  }, [reader, file, img]);
};

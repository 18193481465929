// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPinLocation = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7575 21.7814C13.5905 21.2611 15.5058 19.9636 17.2199 18.0599C18.9287 16.1614 20.5008 13.5897 20.5008 10.5324C20.5008 5.8195 16.6956 2 12.0004 2C7.30515 2 3.5 5.8195 3.5 10.5324C3.5 13.5889 5.07209 16.1614 6.78087 18.0599C8.49492 19.9636 10.411 21.2604 11.2433 21.7814C11.4701 21.9246 11.7322 22.0008 12.0004 22.0008C12.2685 22.0008 12.5307 21.9246 12.7575 21.7814ZM8.1743 16.8053C6.6142 15.072 5.375 12.9308 5.375 10.5324C5.375 6.84838 8.34733 3.875 12.0004 3.875C15.6534 3.875 18.6258 6.84838 18.6258 10.5324C18.6258 12.9315 17.3866 15.0719 15.8265 16.8053C14.6803 18.0783 13.423 19.0608 12.5388 19.678C12.215 19.9039 11.7863 19.9038 11.4625 19.6778C10.5776 19.0599 9.32011 18.0778 8.1743 16.8053Z"
      />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconX = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.6538 17.655L14.3963 12.3975C14.1788 12.18 14.1788 11.8275 14.3963 11.61L19.635 6.37128C20.1525 5.85378 20.25 5.01378 19.7888 4.44753C19.2563 3.79878 18.2963 3.76128 17.7188 4.34253L12.4275 9.63378C12.21 9.85128 11.8575 9.85128 11.64 9.63378L6.3525 4.34628C5.835 3.82878 4.995 3.73128 4.42875 4.19253C3.78 4.72503 3.7425 5.68503 4.32375 6.26253L9.6675 11.6063C9.885 11.8238 9.885 12.1763 9.6675 12.3938L4.39875 17.6625C3.88125 18.18 3.78375 19.02 4.245 19.5863C4.7775 20.235 5.7375 20.2725 6.315 19.6913L11.64 14.3663C11.8575 14.1488 12.21 14.1488 12.4275 14.3663L17.7413 19.68C18.3188 20.2575 19.2788 20.2238 19.8113 19.575C20.2725 19.0088 20.175 18.1688 19.6575 17.6513L19.6538 17.655Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCopy = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9325 3.42374C19.1887 3.59624 20.175 4.58249 20.3475 5.83874H20.3437C20.715 8.54624 20.715 11.295 20.3437 14.0025C20.1937 15.0975 19.425 15.9862 18.3975 16.3087C18.2025 16.3687 18.0075 16.215 18.0225 16.0125C18.0525 15.6 18.075 15.1875 18.0862 14.775C18.09 14.5612 18.1725 14.3625 18.3 14.1937C18.3937 14.07 18.4612 13.9162 18.4837 13.7512C18.8287 11.2125 18.8287 8.63624 18.4837 6.09374C18.4275 5.67374 18.0937 5.33999 17.6737 5.28374C16.41 5.11124 15.12 5.02499 13.845 5.02499C12.57 5.02499 11.28 5.11124 10.0162 5.28374C9.73872 5.32124 9.49872 5.48249 9.35247 5.70749C9.25872 5.84999 9.10497 5.93999 8.93622 5.94749C8.52747 5.96624 8.02122 5.99624 7.68747 6.01874C7.50372 6.02999 7.35372 5.86874 7.38372 5.68499C7.39497 5.60624 7.40622 5.53499 7.41747 5.49749C7.70622 4.40624 8.62497 3.57749 9.76497 3.42374C11.1225 3.23999 12.4837 3.14624 13.8487 3.14624C15.2137 3.14624 16.5787 3.23999 17.9325 3.42374ZM13.8187 9.54745C12.555 9.37495 11.265 9.2887 9.98996 9.2887C8.71496 9.2887 7.42496 9.37495 6.16121 9.54745C5.74121 9.6037 5.40746 9.93745 5.35121 10.3574C5.00621 12.8962 5.00621 15.4724 5.35121 18.0149C5.40746 18.4349 5.74121 18.7687 6.16121 18.8249C7.42496 18.9974 8.71496 19.0837 9.98996 19.0837C11.265 19.0837 12.555 18.9974 13.8187 18.8249C14.2387 18.7687 14.5725 18.4349 14.6287 18.0149C14.9737 15.4762 14.9737 12.8999 14.6287 10.3574C14.5725 9.93745 14.2387 9.6037 13.8187 9.54745ZM5.90621 7.6912C7.26371 7.50745 8.62496 7.4137 9.98996 7.4137C11.355 7.4137 12.72 7.50745 14.0737 7.6912C15.33 7.8637 16.3162 8.84995 16.4887 10.1062C16.86 12.8137 16.86 15.5624 16.4887 18.2699C16.3162 19.5262 15.33 20.5124 14.0737 20.6849C12.7162 20.8687 11.355 20.9624 9.98996 20.9624C8.62496 20.9624 7.25996 20.8687 5.90621 20.6849C4.64996 20.5124 3.66371 19.5262 3.49121 18.2699C3.11996 15.5624 3.11996 12.8137 3.49121 10.1062C3.66371 8.84995 4.64996 7.8637 5.90621 7.6912Z"
      />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconArrowLeft = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0225 10.5344H5.90251L10.0763 5.47944C10.5863 4.87194 10.5638 3.97194 10.0313 3.42819C9.73501 3.12444 9.31876 2.95944 8.89876 2.97819C8.47501 2.99319 8.07751 3.19194 7.80376 3.51819L1.95001 10.5907C1.24876 11.4269 1.24876 12.6419 1.95001 13.4744L7.73251 20.4869C8.01751 20.8282 8.43751 21.0232 8.88376 21.0232C9.21751 21.0232 9.54376 20.9107 9.80626 20.7044C10.1175 20.4607 10.3125 20.1044 10.3538 19.6994C10.3988 19.2719 10.2675 18.8369 9.99751 18.5144L5.90251 13.5269H21.075C21.4988 13.5269 21.9038 13.3469 22.1888 13.0319C22.4738 12.7169 22.6125 12.2969 22.5675 11.8732C22.4925 11.1194 21.8138 10.5269 21.0225 10.5269V10.5344Z" />
    </svg>
  );
};

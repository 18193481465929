import { getVersion, proxy, ref, subscribe } from "valtio";
import uniqBy from "lodash/uniqBy";
import { useEffect, useMemo } from "react";
import { createContext, useContext, ReactNode } from "react";

class FileUploadStore {
  attached: boolean = false;
  error?: Error;
  dragReferences = 0;
  files: File[] = [];

  get isDragging() {
    return this.dragReferences > 0;
  }

  // Methods
  handleDrag(event: "enter" | "leave" | "drop") {
    switch (event) {
      case "enter":
        this.dragReferences += 1;
        break;
      case "leave":
        this.dragReferences -= 1;
        break;
      case "drop":
        this.dragReferences = 0;
        break;
    }
  }

  // important to store references to the files, not proxies:
  setFiles(newFiles: File[]) {
    this.files = newFiles.map(ref);
  }

  addFiles(newFiles: File[] | null | undefined, maxAllowed: number) {
    if (newFiles) {
      const merged = uniqBy([...this.files, ...newFiles], "name");
      this.setFiles(merged.slice(0, maxAllowed));
    }
  }

  deleteFile(file: File | null | undefined) {
    if (file) {
      this.files = this.files.filter((f) => f.name !== file.name);
    }
  }
}

export const createFileUploadStore = () => {
  const p = proxy(new FileUploadStore());

  subscribe(p, () => {
    p.files.some((f) => {
      if (getVersion(f)) {
        const error = new Error(
          "Do not add Files directly, use ref(), addFiles, or setFiles.",
        );

        if (p.attached) p.error ??= error;
        else throw error;
      }
    });
  });

  return p;
};

const FileUploadContext = createContext<ReturnType<
  typeof createFileUploadStore
> | null>(null);

export const Provider = ({ children }: { children: ReactNode }) => {
  const store = useMemo(() => createFileUploadStore(), []);

  useEffect(() => {
    store.attached = true;
    return () => {
      store.attached = false;
    };
  }, [store]);

  if (store.error) throw store.error;

  return (
    <FileUploadContext.Provider value={store}>
      {children}
    </FileUploadContext.Provider>
  );
};

let sketchyGlobalStore: undefined | ReturnType<typeof createFileUploadStore> =
  undefined;

export const useStore = () => {
  const store = useContext(FileUploadContext);
  if (!store) {
    return (sketchyGlobalStore ||= createFileUploadStore());
  }
  return store;
};

export const useOnFilesChange = (callback: (files: File[]) => void) => {
  const store = useStore();

  useEffect(
    () =>
      subscribe(store, () => {
        callback(store.files);
      }),
    [store, callback],
  );
};

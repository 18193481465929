// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconXCircleFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM16.485 16.3425C16.3012 16.5675 16.0275 16.6988 15.7388 16.6988C15.48 16.6988 15.24 16.5975 15.06 16.4175L12.1088 13.4663C12.0863 13.4438 12.0563 13.4288 12.0225 13.4288C11.9888 13.4288 11.9587 13.44 11.9362 13.4663L8.98125 16.4213C8.80125 16.6013 8.5575 16.7025 8.3025 16.7025C8.01375 16.7025 7.74 16.575 7.55625 16.3463C7.24875 15.9713 7.29 15.39 7.65375 15.0263L10.5787 12.1013C10.6275 12.0525 10.6275 11.9775 10.5787 11.9288L7.6125 8.9625C7.42125 8.77125 7.32 8.505 7.33125 8.235C7.3425 7.965 7.47375 7.71 7.68375 7.5375C7.84875 7.4025 8.05125 7.33125 8.2725 7.33125C8.5425 7.33125 8.80875 7.44375 9.00375 7.635L11.94 10.5713C11.9625 10.5938 11.9925 10.6088 12.0262 10.6088C12.06 10.6088 12.09 10.5975 12.1125 10.5713L15.0525 7.63125C15.2325 7.45125 15.4763 7.35 15.7313 7.35C16.02 7.35 16.2937 7.4775 16.4775 7.70625C16.785 8.085 16.7438 8.6625 16.38 9.02625L13.4738 11.9325C13.425 11.9813 13.425 12.0563 13.4738 12.105L16.3912 15.0225C16.755 15.3863 16.7963 15.9638 16.4888 16.3425H16.485Z" />
    </svg>
  );
};

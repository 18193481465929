// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconArrowDown = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5345 2.97558V18.0956L5.4795 13.9218C4.872 13.4118 3.972 13.4343 3.42825 13.9668C3.1245 14.2631 2.9595 14.6793 2.97825 15.0993C2.99325 15.5231 3.192 15.9206 3.51825 16.1943L10.5907 22.0518C11.427 22.7531 12.642 22.7531 13.4745 22.0518L20.487 16.2693C20.8282 15.9843 21.0232 15.5643 21.0232 15.1181C21.0232 14.7843 20.9107 14.4581 20.7045 14.1956C20.4607 13.8843 20.1045 13.6893 19.6995 13.6481C19.272 13.6031 18.837 13.7343 18.5145 14.0043L13.527 18.0993V2.92308C13.527 2.49933 13.347 2.09433 13.032 1.80933C12.717 1.52433 12.297 1.38558 11.8732 1.43058C11.1195 1.50558 10.527 2.18433 10.527 2.97558H10.5345Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconTriangleDown = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6084 9.03837C18.4246 8.80962 18.1471 8.70462 17.8659 8.70837V8.70462L6.20337 8.68587C6.17337 8.68212 6.14337 8.68587 6.11337 8.68587H6.08337C5.80212 8.69337 5.52838 8.82462 5.34838 9.07962C5.06338 9.48087 5.16838 10.0396 5.53963 10.3621L10.6396 14.8096C11.4121 15.4846 12.5784 15.4846 13.3509 14.8096L18.4921 10.3246C18.7059 10.1409 18.8146 9.87837 18.8146 9.61962C18.8109 9.41337 18.7434 9.20712 18.6084 9.03837Z" />
    </svg>
  );
};

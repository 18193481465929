// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconSearchPlain = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4154 18.6787L16.1291 14.295C16.0016 14.1637 15.9904 13.965 16.0954 13.815C17.8054 11.325 17.5879 7.89749 15.4054 5.65124C14.0179 4.22249 12.1316 3.56999 10.2866 3.70499C8.6141 3.82499 6.9791 4.59374 5.7866 6.01499C3.7766 8.40749 3.7991 11.985 5.82785 14.3587C7.2341 16.0087 9.2591 16.7625 11.2316 16.62C12.3116 16.5412 13.3729 16.1887 14.3141 15.5662C14.4679 15.465 14.6666 15.48 14.7941 15.6112L19.1179 20.0362C19.3204 20.2425 19.5904 20.3362 19.8566 20.3175C20.0591 20.3025 20.2579 20.2237 20.4191 20.0775C20.8166 19.7175 20.7904 19.065 20.4191 18.6825L20.4154 18.6787ZM7.4591 13.365C6.6041 12.4837 6.14285 11.3212 6.15785 10.095C6.1766 8.86499 6.6716 7.71749 7.55285 6.86249C8.3291 6.10874 9.34535 5.65124 10.4216 5.57624C11.7829 5.47874 13.1104 5.98124 14.0591 6.95999C14.9141 7.84124 15.3754 9.00374 15.3604 10.23C15.3454 11.4562 14.8466 12.6075 13.9654 13.4625C13.1891 14.2162 12.1729 14.6737 11.0966 14.7487C9.73535 14.8462 8.40785 14.3437 7.4591 13.365Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconArrowsCrossHorizonal = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0675 14.6401H8.04377C7.72502 14.6401 7.55253 14.2689 7.75503 14.0251L9.22127 12.2739C9.53627 11.8989 9.55128 11.3289 9.20628 10.9764C8.81253 10.5752 8.16752 10.6089 7.81502 11.0289L4.51503 14.9739C4.22253 15.3227 4.22253 15.8289 4.51503 16.1777L7.81502 20.1226C7.99877 20.3439 8.26502 20.4602 8.53502 20.4602C8.73752 20.4602 8.94003 20.3964 9.11253 20.2614C9.53253 19.9314 9.55879 19.2827 9.21379 18.8739L7.75503 17.1302C7.55253 16.8864 7.72502 16.5151 8.04377 16.5151H18.12C18.6713 16.5151 19.11 16.0426 19.0538 15.4801C19.005 14.9964 18.5588 14.6401 18.0675 14.6401Z" />
      <path d="M19.485 7.81142L16.2188 3.90766C15.9038 3.53266 15.345 3.4164 14.94 3.69015C14.475 4.0089 14.3925 4.64642 14.745 5.07017L16.245 6.86266C16.4475 7.10641 16.275 7.47767 15.9563 7.47767H5.93254C5.44129 7.47767 4.99504 7.83016 4.94629 8.31766C4.89004 8.88016 5.32878 9.35267 5.88003 9.35267H15.9563C16.275 9.35267 16.4475 9.72392 16.245 9.96767L14.7863 11.7114C14.445 12.1202 14.4675 12.7727 14.8875 13.1027C15.0563 13.2339 15.2625 13.3014 15.465 13.3014C15.7313 13.3014 15.9975 13.1889 16.185 12.9639L19.485 9.01891C19.7775 8.66641 19.7775 8.15642 19.485 7.81142Z" />
    </svg>
  );
};

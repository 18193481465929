// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconDivisionThick = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0888 5.58192C14.0888 6.73692 13.1513 7.67066 12 7.67066C10.8488 7.67066 9.91128 6.73317 9.91128 5.58192C9.91128 4.43067 10.8488 3.49316 12 3.49316C13.1513 3.49316 14.0888 4.43067 14.0888 5.58192ZM12 16.3294C10.845 16.3294 9.91128 17.2669 9.91128 18.4182C9.91128 19.5694 10.8488 20.5069 12 20.5069C13.1513 20.5069 14.0888 19.5694 14.0888 18.4182C14.0888 17.2669 13.1513 16.3294 12 16.3294ZM20.8088 12.0019C20.8088 11.1732 20.1375 10.5019 19.3088 10.5019H4.69128C3.86253 10.5019 3.19128 11.1732 3.19128 12.0019C3.19128 12.8307 3.86253 13.5019 4.69128 13.5019H19.305C20.1338 13.5019 20.805 12.8307 20.805 12.0019H20.8088Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCheckCircleFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.175 2.625 12 2.625ZM16.005 9.27375C12.9225 13.0238 11.3475 16.275 11.3325 16.3088C11.1937 16.5975 10.92 16.7925 10.605 16.8338C10.5638 16.8375 10.5263 16.8413 10.485 16.8413C10.2113 16.8413 9.94877 16.7213 9.76877 16.5075L7.38377 13.68C7.05002 13.2825 7.09875 12.6938 7.49625 12.36C7.89375 12.0263 8.4825 12.075 8.81625 12.4725L10.3087 14.2425C11.07 12.8963 12.4763 10.6163 14.5575 8.08501C14.8875 7.68376 15.48 7.6275 15.8775 7.9575C16.275 8.28375 16.3313 8.8725 16.005 9.27375Z" />
    </svg>
  );
};

import type { FC } from "react";
import FilePreview from "./FilePreview";
import MediaPreview from "./MediaPreview";

type Props = {
  files: readonly File[];
};

const hasNonThumbnailFile = (files: readonly File[]) =>
  files.some(
    (file) =>
      !["image/", "video/"].some((s) => {
        return file.type.startsWith(s);
      }),
  );

const Preview: FC<Props> = ({ files }) => {
  return !hasNonThumbnailFile(files) ? (
    <MediaPreview files={files} />
  ) : (
    <FilePreview file={files[0]} />
  );
};
export default Preview;

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconGraduate = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.13753 20.7675C5.19003 20.97 5.07003 21.1763 4.86753 21.225C4.25628 21.3825 3.61878 21.3825 3.00753 21.2325L2.97753 21.225C2.79003 21.1763 2.67003 20.9963 2.69628 20.8088C2.78628 20.1825 3.21753 18.4913 3.28503 17.8613L3.36003 17.1825V13.53C3.36003 13.3463 3.52878 13.215 3.70878 13.2563C3.87003 13.2975 4.03128 13.335 4.19628 13.3763C4.36128 13.4175 4.48503 13.5675 4.48503 13.74V17.1825L4.49253 17.25C4.62003 18.4275 4.83378 19.5938 5.13378 20.7413L5.14128 20.7675H5.13753ZM18.9225 11.1C18.7875 11.0213 18.615 11.0438 18.495 11.145C17.445 12.0413 16.41 12.8738 15.5475 13.5L14.8538 14.0025C14.235 14.4525 13.2563 14.7525 12.4275 14.7525C12.3188 14.7525 12.2138 14.7488 12.1125 14.7375L11.2575 14.6513C10.2075 14.5425 8.91003 14.3438 7.57128 14.0925C7.41378 14.0625 7.25253 14.1338 7.17378 14.2725C6.40128 15.63 6.01128 17.1938 6.09378 18.8025C6.11253 19.1738 6.53628 19.3763 6.85878 19.1813C7.54878 18.7688 8.30628 18.4388 9.12753 18.2213C10.725 17.7938 12.3338 17.8238 13.8263 18.225C14.3588 18.3675 14.9175 18.2175 15.3 17.8313C16.3725 16.7438 17.7413 15.9113 19.3388 15.4838C20.16 15.2625 20.9813 15.1688 21.7913 15.18C22.17 15.1838 22.425 14.8013 22.2488 14.4713C21.4763 13.035 20.31 11.88 18.9263 11.0963L18.9225 11.1ZM22.0013 6.30378C20.2163 8.22378 17.0138 11.0513 14.8913 12.5888L14.1938 13.0913C13.7588 13.4063 13.005 13.6275 12.4238 13.6275C12.3563 13.6275 12.2888 13.6275 12.225 13.6163L11.37 13.53C8.76378 13.26 4.57878 12.4125 2.07003 11.6438C1.53753 11.4788 1.41378 11.0138 1.79253 10.6088C3.58128 8.68878 6.78003 5.86128 8.90628 4.32378L9.60003 3.82128C10.035 3.50628 10.7888 3.28503 11.37 3.28503C11.4375 3.28503 11.505 3.28503 11.5688 3.29628L12.42 3.38253C15.03 3.65253 19.215 4.50003 21.7238 5.26878C22.2563 5.43003 22.38 5.89878 22.0013 6.30378ZM12.9638 7.88253C12.87 7.53378 12.2213 7.15503 11.5913 7.32378C10.9613 7.49253 10.59 8.14503 10.6838 8.49378C10.7775 8.84253 11.3063 8.77878 11.9363 8.61003C12.5663 8.44128 13.0575 8.23503 12.9638 7.88253Z" />
    </svg>
  );
};

import type { FC } from "react";
import MediaThumbnail from "./thumbnails/MediaThumbnail";
import styles from "./MediaPreview.module.css";

type Props = {
  files: readonly File[];
};

const MediaPreview: FC<Props> = ({ files }) => {
  return (
    <ul className={styles.container}>
      {files.map((f) => (
        <li key={f.name} className={styles.tile}>
          <MediaThumbnail file={f} />
        </li>
      ))}
    </ul>
  );
};

export default MediaPreview;

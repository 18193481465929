// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconDude = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.625 12C2.625 6.82125 6.82125 2.625 12 2.625C17.175 2.625 21.375 6.82125 21.375 12C21.375 17.1788 17.1788 21.375 12 21.375C6.82125 21.375 2.625 17.1788 2.625 12ZM19.5 12C19.5 7.86375 16.1362 4.5 12 4.5C7.86375 4.5 4.5 7.86375 4.5 12C4.5 16.1362 7.86375 19.5 12 19.5C16.1362 19.5 19.5 16.1362 19.5 12Z"
      />
      <path d="M9.6945 10.3096C10.0446 9.79631 9.91238 9.09639 9.39911 8.74626C8.88584 8.39613 8.18591 8.52838 7.83578 9.04165C7.48565 9.55492 7.6179 10.2548 8.13118 10.605C8.64445 10.9551 9.34437 10.8229 9.6945 10.3096Z" />
      <path d="M15.2438 10.8C15.8651 10.8 16.3688 10.2963 16.3688 9.67499C16.3688 9.05367 15.8651 8.54999 15.2438 8.54999C14.6224 8.54999 14.1188 9.05367 14.1188 9.67499C14.1188 10.2963 14.6224 10.8 15.2438 10.8Z" />
      <path d="M11.9513 17.6025L11.9775 17.5687L12.0038 17.6025C15.1163 17.7412 16.905 15.4125 16.7475 13.92C16.59 12.4275 15.1988 12.39 14.295 12.7575C12.915 13.3275 11.1825 13.4512 9.65251 12.7612C8.76376 12.3562 7.35751 12.4312 7.20001 13.9275C7.04626 15.42 8.83876 17.7487 11.9475 17.6025H11.9513Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconClock = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.5C16.1362 4.5 19.5 7.86375 19.5 12C19.5 16.1362 16.1362 19.5 12 19.5C7.86375 19.5 4.5 16.1362 4.5 12C4.5 7.86375 7.8675 4.5 12 4.5ZM12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM15.4538 16.7175C15.8476 16.3838 15.8962 15.7912 15.5625 15.3975L12.6038 11.9175C12.4575 11.745 12.375 11.5275 12.375 11.3025V7.00126C12.375 6.48376 11.955 6.06376 11.4375 6.06376C10.92 6.06376 10.5 6.48376 10.5 7.00126V11.2988C10.5 11.9738 10.7438 12.6263 11.1788 13.1325L14.1301 16.6088C14.3138 16.8263 14.58 16.9388 14.8463 16.9388C15.0638 16.9388 15.2776 16.8638 15.4538 16.7175Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconTrash = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27839 19.8508C6.37708 16.6013 6.14314 13.2051 6.58314 9.8657C6.58994 9.81414 6.59689 9.76258 6.60401 9.71105L6.63842 9.46676C6.66594 9.27064 6.83454 9.12613 7.03065 9.12613H17.0327C17.2323 9.12613 17.3975 9.27064 17.425 9.46676L17.4594 9.71105C17.4666 9.76258 17.4736 9.81414 17.4804 9.8657C17.9236 13.2051 17.6863 16.6013 16.785 19.8508C16.6611 20.2946 16.2964 20.6249 15.8423 20.7041C13.3202 21.1445 10.7432 21.1445 8.22113 20.7041C7.76696 20.6249 7.40225 20.2912 7.27839 19.8508ZM8.32695 11.1714C8.33519 11.0749 8.41618 11.0011 8.51302 11.0011H15.5514C15.6482 11.0011 15.7291 11.0748 15.7374 11.1712C15.9576 13.7301 15.7496 16.3108 15.1217 18.8071C15.1034 18.8799 15.043 18.9346 14.9686 18.9454C13.0206 19.2307 11.0428 19.2307 9.0948 18.9454C9.02046 18.9346 8.96007 18.8799 8.94175 18.8071C8.31448 16.312 8.10825 13.7314 8.32695 11.1714Z"
      />
      <path d="M8.6237 5.4962C7.29559 5.58566 5.97092 5.72329 4.6497 5.92285C4.30219 5.97446 4.01317 6.20843 3.88242 6.53529L3.84458 6.63163C3.75168 6.86904 3.75168 7.13053 3.84458 7.36794C3.99597 7.74297 4.36068 7.9907 4.76668 7.9907H19.2967C19.7027 7.9907 20.0674 7.74297 20.2188 7.36794C20.3117 7.13053 20.3117 6.86904 20.2188 6.63163L20.181 6.53529C20.0502 6.21187 19.7578 5.97446 19.4137 5.92285C18.0959 5.72329 16.7678 5.58566 15.4397 5.4962C15.2745 5.48588 15.1335 5.37234 15.0853 5.21063L14.7206 3.92725C14.5967 3.49372 14.232 3.17374 13.7882 3.10492C12.6252 2.91913 11.4382 2.91913 10.2752 3.10492C9.83138 3.17718 9.46666 3.49716 9.3428 3.92725L8.97809 5.21063C8.93336 5.37234 8.79229 5.48588 8.6237 5.4962Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPlusCircle = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.5C16.1362 4.5 19.5 7.86375 19.5 12C19.5 16.1362 16.1362 19.5 12 19.5C7.86375 19.5 4.5 16.1362 4.5 12C4.5 7.86375 7.86375 4.5 12 4.5ZM12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.825 17.1788 2.625 12 2.625ZM16.0725 11.1638H13.3125C13.1062 11.1638 12.9375 10.995 12.9375 10.7888V7.65375C12.9375 7.1625 12.585 6.71625 12.0975 6.6675C11.535 6.61125 11.0625 7.05 11.0625 7.60125V10.7888C11.0625 10.995 10.8938 11.1638 10.6875 11.1638H7.92751C7.43626 11.1638 6.99 11.5162 6.94125 12.0037C6.885 12.5662 7.32375 13.0388 7.875 13.0388H10.6875C10.8938 13.0388 11.0625 13.2075 11.0625 13.4138V16.5487C11.0625 17.04 11.415 17.4862 11.9025 17.535C12.465 17.5912 12.9375 17.1525 12.9375 16.6013V13.4138C12.9375 13.2075 13.1062 13.0388 13.3125 13.0388H16.125C16.6763 13.0388 17.115 12.5662 17.0587 12.0037C17.0062 11.5162 16.56 11.1638 16.0725 11.1638Z" />
    </svg>
  );
};

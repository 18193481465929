// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconVideocamera = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6385 8.20316L21.4997 7.44941C21.4022 6.90566 20.8322 6.58691 20.3147 6.78566L19.576 7.07067C18.1697 7.61067 16.8235 8.28566 15.5485 9.07316C15.5185 8.84066 15.4885 8.61191 15.4547 8.37941C15.2785 7.24691 14.3785 6.36191 13.2422 6.20816C10.3435 5.80691 7.40719 5.80691 4.51219 6.20816C3.37594 6.36566 2.47593 7.24691 2.29968 8.37941C1.92468 10.7794 1.92468 13.2207 2.29968 15.6207C2.47593 16.7532 3.37594 17.6382 4.51219 17.7919C7.40719 18.1932 10.3472 18.1932 13.2422 17.7919C14.3785 17.6344 15.2785 16.7532 15.4547 15.6207C15.4922 15.3882 15.5222 15.1594 15.5485 14.9269C16.8235 15.7144 18.1697 16.3894 19.576 16.9294L20.3147 17.2144C20.8322 17.4132 21.3985 17.0944 21.4997 16.5507L21.6385 15.7969C22.096 13.2844 22.096 10.7119 21.6385 8.20316Z" />
    </svg>
  );
};

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMessage = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9961 5.28033C15.5916 5.28033 18.5178 8.20652 18.5178 11.802C18.5178 14.92 16.2977 17.6136 13.2368 18.2013L12.9552 18.2543C11.1391 18.5645 9.32707 18.7196 7.55993 18.7196C7.10284 18.7196 7.27017 18.3115 7.33138 18.1033L7.33954 18.0788L7.34771 18.0544L7.36403 17.9891C7.54768 17.2544 7.37628 16.4586 6.89878 15.8628C5.9846 14.716 5.49486 13.3406 5.47854 11.8755C5.45813 10.141 6.1356 8.4922 7.38036 7.2352C8.62511 5.9782 10.2657 5.28441 12.0002 5.28441M12.0002 3.24383C7.28241 3.24383 3.38898 7.18215 3.43796 11.9C3.45836 13.8793 4.15624 15.6995 5.30305 17.1361C5.38467 17.2381 5.41324 17.3687 5.38467 17.4912L5.36835 17.5524C5.11123 18.4706 4.54395 19.2665 3.75629 19.8093C3.44612 20.0215 3.56855 20.5071 3.93994 20.552C5.14388 20.6908 6.3519 20.7602 7.56401 20.7602C9.59643 20.7602 11.6288 20.5683 13.6327 20.2092H13.6286C17.5792 19.446 20.5625 15.973 20.5625 11.802C20.5625 7.07195 16.7303 3.23975 12.0002 3.23975V3.24383Z" />
    </svg>
  );
};

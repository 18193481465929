// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconNo = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM4.62 10.65C5.16 7.60125 7.60125 5.16 10.65 4.62C12.7275 4.2525 14.6812 4.755 16.2225 5.81625C16.4175 5.95125 16.4475 6.22875 16.2825 6.3975L6.39375 16.2863C6.225 16.455 5.9475 16.425 5.8125 16.2262C4.75125 14.6813 4.24875 12.7312 4.61625 10.65H4.62ZM13.35 19.38C11.2725 19.7475 9.31875 19.245 7.7775 18.1875C7.5825 18.0525 7.5525 17.775 7.7175 17.6063L17.6063 7.7175C17.775 7.54875 18.0525 7.57875 18.1875 7.7775C19.245 9.3225 19.7475 11.2725 19.38 13.35C18.84 16.3988 16.3988 18.84 13.35 19.38Z" />
    </svg>
  );
};

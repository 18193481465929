// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPlusCircleFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM16.125 13.0388H13.3125C13.1062 13.0388 12.9375 13.2075 12.9375 13.4138V16.6013C12.9375 17.1525 12.465 17.5913 11.9025 17.535C11.415 17.4863 11.0625 17.04 11.0625 16.5488V13.4138C11.0625 13.2075 10.8938 13.0388 10.6875 13.0388H7.875C7.32375 13.0388 6.885 12.5663 6.94125 12.0038C6.99 11.5163 7.43626 11.1638 7.92751 11.1638H10.6875C10.8938 11.1638 11.0625 10.995 11.0625 10.7888V7.60126C11.0625 7.05001 11.535 6.61125 12.0975 6.6675C12.585 6.71625 12.9375 7.1625 12.9375 7.65375V10.7888C12.9375 10.995 13.1062 11.1638 13.3125 11.1638H16.0725C16.5637 11.1638 17.01 11.5163 17.0587 12.0038C17.1112 12.5663 16.6725 13.0388 16.125 13.0388Z" />
    </svg>
  );
};

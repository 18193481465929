// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconOpenin = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3863 6.03007L12.4313 13.2601L12.435 13.2563C12.1538 13.5901 11.6738 13.7288 11.28 13.5338C10.71 13.2488 10.5863 12.5401 10.9688 12.0863L16.9538 4.81882C17.055 4.69882 16.9688 4.51132 16.5488 4.50757H13.29C12.7988 4.50382 12.3488 4.15882 12.2963 3.67507C12.2663 3.40507 12.3525 3.15382 12.51 2.96632C12.6825 2.76382 12.9413 2.63257 13.23 2.63257L18.5888 2.64007C19.4063 2.64382 20.1075 3.22882 20.25 4.03507L21.1875 9.31132C21.2888 9.87757 20.8613 10.4138 20.2575 10.4138C19.8263 10.4176 19.41 10.0238 19.335 9.59632L18.7163 6.12007C18.69 5.95882 18.4875 5.90632 18.3863 6.03007ZM6.09371 21.2101C7.68371 21.4276 9.30371 21.5363 10.9125 21.5363H10.9162C12.525 21.5363 14.145 21.4276 15.7387 21.2101C16.485 21.1088 17.1637 20.7751 17.7 20.2388C18.2325 19.7026 18.57 19.0238 18.6712 18.2776C18.8887 16.6876 18.9975 15.0638 18.9975 13.4588C18.9975 12.9413 18.5775 12.5213 18.06 12.5213C17.5425 12.5213 17.1225 12.9413 17.1225 13.4588C17.1225 14.9813 17.0175 16.5188 16.8112 18.0263C16.7662 18.3676 16.6125 18.6751 16.3725 18.9151C16.1287 19.1588 15.8212 19.3126 15.4837 19.3576C12.465 19.7701 9.36371 19.7701 6.34871 19.3576C6.00746 19.3088 5.69996 19.1588 5.45996 18.9151C5.21621 18.6713 5.06246 18.3638 5.01746 18.0263C4.81121 16.5188 4.70621 14.9813 4.70621 13.4588C4.70621 11.9363 4.81121 10.3988 5.01746 8.89131C5.06621 8.55381 5.21621 8.24631 5.45996 8.00256C5.70371 7.76256 6.00746 7.60881 6.34871 7.56381L6.82871 7.49631C8.03621 7.32756 8.59121 7.25256 9.91871 7.25256C10.4362 7.25256 10.8562 6.83256 10.8562 6.31506C10.8562 5.79756 10.4362 5.37756 9.91871 5.37756C8.46577 5.37756 7.79407 5.47057 6.5845 5.63805L6.56996 5.64007L6.09371 5.70381C5.34371 5.80506 4.66496 6.14256 4.13246 6.67506C3.59996 7.21131 3.26246 7.89006 3.16121 8.63631C2.94371 10.2263 2.83496 11.8538 2.83496 13.4588C2.83496 15.0638 2.94371 16.6876 3.16121 18.2776C3.26246 19.0276 3.59996 19.7063 4.13246 20.2388C4.66496 20.7713 5.34371 21.1088 6.09371 21.2101Z"
      />
    </svg>
  );
};

/** useAfterUnmount: is a hook that allows you to run a callback when the component is unmounted.
 * Introduces a delay to ensure the component has actually unmounted, as a useEffect cleanup function can be called extra times.
 * If there's a better way to accomplish this goal in a forward-looking way, please update!
 * This is not a great solution - please try to put this logic in a parent component instead.
 * Trying to get around upcoming changes in useEffect per https://react.dev/reference/react/useEffect#my-cleanup-logic-runs-even-though-my-component-didnt-unmount
 * @param callback - The callback to run when the component is mounted.
 */

import { useEffect, useLayoutEffect, useRef } from "react";

export default function useAfterUnmount(callback: () => void) {
  const mounted = useRef(false);
  const callbackRef = useRef(callback);

  // Update the callback ref whenever it changes
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // eslint-disable-next-line @web-monorepo/prefer-useSideEffectOnMount
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      setTimeout(() => {
        if (mounted.current === false) callbackRef.current();
      }, 0);
    };
  }, []);
}

// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMenu = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2462 12.9356H3.75366C3.23616 12.9356 2.81616 12.5156 2.81616 11.9981C2.81616 11.4806 3.23616 11.0606 3.75366 11.0606H20.2462C20.7637 11.0606 21.1837 11.4806 21.1837 11.9981C21.1837 12.5156 20.7637 12.9356 20.2462 12.9356Z" />
      <path d="M19.5449 7.53564H4.45116C3.93366 7.53564 3.51366 7.11564 3.51366 6.59814C3.51366 6.08064 3.93366 5.66064 4.45116 5.66064H19.5449C20.0624 5.66064 20.4824 6.08064 20.4824 6.59814C20.4824 7.11564 20.0662 7.53564 19.5449 7.53564Z" />
      <path d="M19.5449 18.3394H4.45116C3.93366 18.3394 3.51366 17.9194 3.51366 17.4019C3.51366 16.8844 3.93366 16.4644 4.45116 16.4644H19.5449C20.0624 16.4644 20.4824 16.8844 20.4824 17.4019C20.4824 17.9194 20.0662 18.3394 19.5449 18.3394Z" />
    </svg>
  );
};
